<template>
  <div class="container-fluid">
    <Header />
    <div
      class="mx-auto mt-1 content-outer-container"
      :class="{ 'content-dashboard': $store.state.isDashboard }"
    >
      <div class="mx-auto content-inner-container">
        <div class="wrap-AccountSetup wrap-branch-page">
          <div class="row paj-custom-row">
            <div class="col-12 custom-wrapper-dashboard">
              <div class="d-flex align-items-center flex-wrap mr-2 text-title">
                <img
                  src="@/assets/images/accountsetup/setting.svg"
                  alt=""
                  class="mr-2"
                />
                {{ lbl['main-menu-setting'] }}
              </div>
            </div>

            <div class="col-xl-12 col-lg-12 col-md-12 wrapper-content-tab">
              <div class="d-flex wrapper-select-header">
                <div
                  v-if="isEdit"
                  class="select-header cursor-pointer"
                  :class="{ active: false }"
                  @click="previousAccountSetting"
                >
                  {{ lbl['account-setup-account-setting'] }}
                </div>
                <div v-else class="select-header" :class="{ active: false }">
                  {{ lbl['account-setup-account-setting'] }}
                </div>
                <div
                  v-if="isEdit"
                  class="select-header select-hd-right cursor-pointer"
                  :class="{ active: true }"
                >
                  {{ lbl['account-setup-hq-and-branch'] }}
                </div>
                <div
                  v-else
                  class="select-header select-hd-right"
                  :class="{ active: true }"
                >
                  {{ lbl['account-setup-hq-and-branch'] }}
                </div>
                <div
                  v-if="
                    isUseFacebook &&
                    isEdit &&
                    loginTypeList.includes('Facebook')
                  "
                  class="select-header select-hd-right cursor-pointer"
                  :class="{ active: false }"
                  @click="gotoConnectFacebook"
                >
                  {{ lbl['link-setup-tab-connect-facebook'] }}
                </div>
                <div
                  v-if="
                    isUseFacebook &&
                    !isEdit &&
                    loginTypeList.includes('Facebook')
                  "
                  class="select-header select-hd-right"
                  :class="{ active: false }"
                >
                  {{ lbl['link-setup-tab-connect-facebook'] }}
                </div>
                <div
                  v-if="isEdit"
                  class="select-header select-hd-right cursor-pointer"
                  :class="{ active: false }"
                  @click="gotoConnectSetting"
                >
                  {{ lbl['account-setup-line-connect'] }} /
                  {{ lbl['account-setup-link-setup'] }}
                  <div v-if="setupNotification" class="dot-notification">
                    <img
                      src="@/assets/images/item-inactive-icon.png"
                      alt=""
                      class="item-status-icon ml-0"
                    />
                  </div>
                </div>
                <div
                  v-else
                  class="select-header select-hd-right"
                  :class="{ active: false }"
                >
                  {{ lbl['account-setup-line-connect'] }} /
                  {{ lbl['account-setup-link-setup'] }}
                </div>
              </div>
            </div>
          </div>

          <div class="px-5">
            <a-form :form="form" @submit="handleSubmit">
              <!-- Head -->
              <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                  <a-form-item>
                    <strong class="fz-16">{{
                      lbl['account-setup-head-quarter']
                    }}</strong>
                    <a-input
                      v-decorator="[
                        'head_quarter',
                        {
                          initialValue: '',
                          rules: [
                            {
                              required: true,
                              message: '',
                            },
                          ],
                        },
                      ]"
                      type="text"
                      class="w-100"
                      :disabled="(disableField || isHeadQuarter) && !isHideEdit"
                    />
                  </a-form-item>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                  <a-form-item>
                    <strong class="fz-16">{{
                      lbl['account-setup-telephone-number']
                    }}</strong>
                    <a-input
                      v-decorator="[
                        'contact_number',
                        {
                          initialValue: '',
                          rules: [
                            {
                              required: true,
                              message: '',
                            },
                          ],
                        },
                      ]"
                      class="w-100"
                      :max-length="maxPhoneLength"
                      @keypress="onlyNumber"
                      :disabled="disableField && !isHideEdit"
                    >
                    </a-input>
                  </a-form-item>
                </div>
              </div>

              <!-- Select your location -->
              <div class="row">
                <div class="col-12 mb-3">
                  <strong class="fz-16">{{
                    lbl['account-setup-select-your-location']
                  }}</strong>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                      <div class="btn-map d-flex" @click="openMap">
                        <img
                          src="@/assets/images/accountsetup/map.svg"
                          alt=""
                          width="24"
                          height="24"
                        />
                        <span class="ml-2">
                          {{ lbl['account-setup-open-maps'] }}
                        </span>
                      </div>
                    </div>
                    <div class="col-xl-2 col-lg-3 col-md-4 col-sm-12 col-12">
                      <label class="lbl-manual d-flex"
                        >{{ lbl['account-setup-or-add-manual'] }}
                        <img
                          src="@/assets/images/accountsetup/info.svg"
                          alt=""
                          class="ml-1"
                      /></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12">
                      <a-form-item>
                        <a-input
                          v-decorator="[
                            'latitude',
                            {
                              initialValue: latitude,
                              rules: [
                                {
                                  required: true,
                                  message: '',
                                },
                              ],
                            },
                          ]"
                          prefix="lat : "
                          type="text"
                          class="w-100"
                          @keypress="onlyNumberDecimal"
                          @change="changeLatitude"
                          :disabled="disableField && !isHideEdit"
                        />
                      </a-form-item>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12">
                      <a-form-item>
                        <a-input
                          v-decorator="[
                            'longtitude',
                            {
                              initialValue: longtitude,
                              rules: [
                                {
                                  required: true,
                                  message: '',
                                },
                              ],
                            },
                          ]"
                          prefix="lng : "
                          type="text"
                          class="w-100"
                          @keypress="onlyNumberDecimal"
                          @change="changeLongtitude"
                          :disabled="disableField && !isHideEdit"
                        />
                      </a-form-item>
                    </div>
                  </div>

                  <GoogleMap
                    :show="showGoogleMap"
                    @changeCloseGoogleMap="changeCloseGoogleMap($event)"
                    @changeSubmitGoogleMap="changeSubmitGoogleMap($event)"
                  />
                </div>
              </div>

              <hr class="bw-3" />

              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                  <strong class="fz-16">
                    {{ lbl['company-setup-branch-name'] }}
                    <a-badge
                      v-if="countBranch > 0"
                      :count="countBranch"
                      :overflow-count="9999"
                      :number-style="{ backgroundColor: '#f9a601' }"
                      class="mr-1"
                    />
                  </strong>
                  <span class="upgrade-required">
                    * {{ lbl['account-setup-upgrade-plan-required'] }}
                  </span>
                </div>
                <div
                  class="
                    col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12
                    d-flex
                    justify-end
                    table-button
                  "
                >
                  <a-button
                    v-if="isEditBranch"
                    class="btn-red-outline ml-3"
                    @click="clickCancleEditBranch()"
                    :disabled="disableField && !isHideEdit"
                  >
                    <span>
                      {{ lbl['confirm-box-cancel-button'] }}
                    </span>
                  </a-button>
                  <a-button
                    v-if="isEditBranch"
                    class="btn-orange-outline ml-3"
                    html-type="submit"
                    type="submit"
                    :disabled="disableField && !isHideEdit"
                  >
                    <span>
                      {{ lbl['company-setup-save-button'] }}
                    </span>
                  </a-button>
                  <a-button
                    v-else
                    class="btn-orange-outline ml-3"
                    :disabled="disableField"
                    @click="addBranch()"
                  >
                    <img
                      src="@/assets/images/accountsetup/account_add.svg"
                      alt=""
                      width="24"
                      height="24"
                    />
                    <span class="ml-2">
                      {{ lbl['account-setup-add-branch'] }}
                    </span>
                  </a-button>
                </div>

                <div class="col-12">
                  <a-table
                    row-key="RowKey"
                    :columns="columns"
                    :data-source="dataSource"
                    :pagination="false"
                    :showHeader="false"
                  >
                    <template slot="date" slot-scope="text">
                      {{ dateShortWithTimeFormat(text) }}
                    </template>
                    <template slot="isname" slot-scope="text">
                      <span>
                        {{ text ? text : '-' }}
                      </span>
                    </template>
                    <template slot="iscontact_number" slot-scope="text">
                      <span>
                        {{ text ? text : '-' }}
                      </span>
                    </template>
                    <template slot="create_by" slot-scope="text">
                      <span>
                        {{
                          text
                            ? lbl['branch-create-by-import']
                            : lbl['branch-create-by-web']
                        }}
                      </span>
                    </template>
                    <template slot="latlng" slot-scope="text, record">
                      <span>
                        {{ record.latitude ? record.latitude : 0 }} /
                        {{ record.longtitude ? record.longtitude : 0 }}
                      </span>
                    </template>
                    <template slot="action" slot-scope="text, record">
                      <div v-if="record.import" class="d-flex box-action">
                        <a
                          type="primary"
                          class="btn-action-branch"
                          :disabled="isHideEdit"
                          :class="{ 'none-event': isHideEdit }"
                          @click="() => onEdit(record.key)"
                        >
                          {{ lbl['account-setup-button-edit'] }}
                        </a>
                      </div>
                      <div v-else class="d-flex box-action">
                        <a
                          type="primary"
                          class="btn-action-branch"
                          :disabled="isHideEdit"
                          :class="{ 'none-event': isHideEdit }"
                          @click="() => onEdit(record.key)"
                        >
                          {{ lbl['account-setup-button-edit'] }}
                        </a>

                        <a-tooltip
                          v-if="record.name != 'Headquarter'"
                          placement="top"
                        >
                          <template slot="title"
                            ><div>
                              {{ lbl['smart-target-delete'] }}
                            </div></template
                          >
                          <div class="mx-3 mb-2">
                            <a-icon
                              :style="{
                                color: isHideEdit ? '#c4c4c4' : '#ff5252',
                                fontSize: '18px ',
                              }"
                              theme="filled"
                              type="delete"
                              :class="{ 'none-event': isHideEdit }"
                              @click="onDelete(record.key)"
                            />
                          </div>
                        </a-tooltip>
                      </div>
                    </template>
                  </a-table>
                </div>
              </div>

              <!-- Footer Button -->
              <div class="row mt-5">
                <div class="col-12">
                  <hr />
                  <b-button
                    variant="warning"
                    class="btn-submit float-right"
                    @click="handleSaveAndNext"
                  >
                    {{ lbl['account-setup-save-and-next'] }}
                  </b-button>
                </div>
              </div>
            </a-form>
          </div>
        </div>
      </div>
    </div>

    <ConfirmModal
      :isOpenModal="confirmModalSetting.isOpenModal"
      :modalType="confirmModalSetting.modalType"
      :title="confirmModalSetting.title"
      :details="confirmModalSetting.details"
      :backto="confirmModalSetting.backto"
      :onConfirm="confirmModalSetting.onConfirm"
      :onCancel="confirmModalSetting.onCancel"
    />

    <AlertModal
      :isOpenModal="alertModalSetting.isOpenModal"
      :modalType="alertModalSetting.modalType"
      :title="alertModalSetting.title"
      :details="alertModalSetting.details"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import Account from '@/helper/AccountHelper.js'
import Mixin from '@/mixin/Mixin'
import Header from '@/components/Layout/Header'
import GoogleMap from '@/components/Maps/GoogleMap.vue'
import BzbsProfile from '@/core/Account/service/BzbsProfile'
import Vue from 'vue'
import BzbsUserProfile from '@/core/UserProfile/service/BzbsUserProfile'
import ConfirmModal from '@/components/Modal/Component/confirmmodal2'
import AlertModal from '@/components/Modal/Component/alertmodal2'

const columns = [
  {
    dataIndex: 'display_name',
    align: 'left',
    scopedSlots: {
      customRender: 'isname',
    },
  },
  {
    dataIndex: 'contact_number',
    align: 'left',
    scopedSlots: {
      customRender: 'iscontact_number',
    },
    width: '30%',
  },
  {
    dataIndex: 'latitude',
    align: 'left',
    scopedSlots: {
      customRender: 'latlng',
    },
    width: '30%',
  },
  {
    dataIndex: 'import',
    align: 'left',
    scopedSlots: {
      customRender: 'create_by',
    },
    width: '120px',
  },
  {
    dataIndex: 'key',
    align: 'center',
    scopedSlots: {
      customRender: 'action',
    },
    width: '120px',
  },
]

export default {
  name: 'BranchSetting',
  mixins: [Mixin],
  components: {
    Header,
    GoogleMap,
    ConfirmModal,
    AlertModal,
  },
  data: function () {
    return {
      setupNotification: false,
      optionCountry: [],
      country: '+66',
      showGoogleMap: false,
      latitude: '',
      longtitude: '',
      columns,
      dataSource: [],
      oriDataSource: [],
      editData: {},
      editableData: '',
      isEditBranch: false,
      isHideEdit: false,
      isEdit: false,
      params: {},
      storeType: '',
      BranchAmount: null,
      maxPhoneLength: 10,
      userData: {},
      disableField: false,
      confirmModalSetting: {
        title: '',
        detail: '',
        modalType: '',
        backto: '',
        onConfirm: null,
        onCancel: null,
        isOpenModal: false,
      },
      alertModalSetting: {
        title: '',
        detail: '',
        modalType: '',
        isOpenModal: false,
      },
      isHeadQuarter: false,
      countBranch: 0,
      crmPackage: {},
      isUseFacebook: false,
      isPH: false,
      loginType: null,
      loginTypeList: [],
    }
  },
  watch: {
    dataSource() {
      let configSelfOnBoard = false
      try {
        let parse = JSON.parse(localStorage.getItem('bzbsCRMConfig'))
        if (parse) {
          configSelfOnBoard = parse.buzzebeesConfigSelfOnBoard
            ? parse.buzzebeesConfigSelfOnBoard
            : false
        } else {
          configSelfOnBoard = Vue.bzbsConfig.businessRule.open.package
        }
      } catch (e) {
        configSelfOnBoard = Vue.bzbsConfig.businessRule.open.package
      }

      if (configSelfOnBoard) {
        if (this.crmPackage.PackageId == 'basicplus') {
          if (this.dataSource.length < 2) {
            this.disableField = false
          } else {
            this.disableField = true
          }
        } else {
          if (this.dataSource.length < this.BranchAmount) {
            this.disableField = false
          } else {
            this.disableField = true
          }
        }
      } else {
        if (this.dataSource.length < this.BranchAmount) {
          this.disableField = false
        } else {
          this.disableField = true
        }
      }
    },
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: 'branch-setting',
    })
  },
  created() {
    this.handleFooter(true)
    this.isUseFacebook = Vue.bzbsConfig.businessRule.open.facebook
    this.isPH = Vue.bzbsConfig.businessRule.webFor.PH
    this.crmPackage = Account.getCachePackage()
    Account.bzbsAnalyticTracking(
      'setting_account_page',
      'setting_account_branch',
      'view_branch',
      'on view',
    )
    this.init()
  },
  methods: {
    init() {
      this.getCRMPlusProfile()
      this.maxPhoneLength = Vue.bzbsConfig.businessRule.max.phoneLength
    },
    getCRMPlusProfile() {
      return new Promise(resolve => {
        BzbsProfile.getCRMPlusProfile()
          .then(res => {
            this.genDataProfile(res.data)
            this.checkAccountSetup(res.data)
            this.userData = res.data
            resolve(res.data)
          })
          .catch(error => {
            console.log('getCRMPlusProfile error', error)
            window.location.reload()
            resolve(error)
          })
      })
    },
    checkAccountSetup(data) {
      if (data.LoginType.toLowerCase().includes('line')) {
        if ((data.LiffLanding || data.LiffId) && data.AppId) {
          this.setupNotification = false
        } else {
          this.setupNotification = true
        }
      } else {
        if (data.AppId) {
          this.setupNotification = false
        } else {
          this.setupNotification = true
        }
      }
    },
    genDataProfile(data) {
      this.storeType = data.StoreType
      this.loginType = data.LoginType ? data.LoginType.toLowerCase() : 'line'
      this.arrList(data.LoginType)
      this.params.agencyId = data.SponsorId
      this.params.business_name = data.BusinessName
      this.params.business_type = data.BusinessType
      this.BranchAmount = data.BranchAmount ? data.BranchAmount : 5
      if (data.BusinessName) {
        this.isEdit = true
      } else {
        this.isEdit = false
      }
      if (data.Stores) {
        if (data.Stores.length > 0) {
          this.countBranch = data.Stores.length
        }
        this.dataSource = _.map(data.Stores, e => {
          return {
            id: e.ID,
            name: e.Name,
            display_name: e.Name + ' (' + e.ID + ')',
            contact_number: e.ContactNumber,
            latitude: e.Latitude,
            longtitude: e.Longtitude,
            import: e.Import,
            isheadoffice: e.IsHeadOffice,
          }
        })
        this.setKey()
      } else {
        this.dataSource = []
        this.countBranch = 0
      }
    },
    onlyNumber($event) {
      const regex = /^[0-9]\d*$/
      var match = $event.key.match(regex)

      if (match == null) {
        $event.preventDefault()
      }
    },
    onlyNumberDecimal($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault()
      }
    },
    changeSelectCountry(e) {
      this.country = e
    },
    openMap() {
      console.log('openMap')
      Account.bzbsAnalyticTracking(
        'setting_account_page',
        'setting_account_branch',
        'click_branch_open_map',
        'on click',
      )
      this.showGoogleMap = true
    },
    changeCloseGoogleMap() {
      console.log('CloseGoogleMap : ')
      Account.bzbsAnalyticTracking(
        'setting_account_page',
        'setting_account_branch',
        'click_branch_add_cancel',
        'on click',
      )
      this.showGoogleMap = false
    },
    changeSubmitGoogleMap(res) {
      console.log('SubmitGoogleMap lat : ', res.lat, 'lng : ', res.lng)
      Account.bzbsAnalyticTracking(
        'setting_account_page',
        'setting_account_branch',
        'click_branch_add_save',
        'on click',
      )
      this.showGoogleMap = false
      if (res) {
        this.latitude = res.lat
        this.longtitude = res.lng
        this.form.setFieldsValue({
          latitude: res.lat,
          longtitude: res.lng,
        })
      }
    },
    changeLatitude(e) {
      console.log('changeLatitude ; ', e.target.value)
      this.latitude = e.target.value
    },
    changeLongtitude(e) {
      console.log('changeLongtitude ; ', e.target.value)
      this.longtitude = e.target.value
    },
    onEdit(key) {
      this.oriDataSource = this.dataSource
      console.log('onEdit : ', key)
      this.editableData = key
      this.isEditBranch = true
      this.isHideEdit = true
      this.editData = this.dataSource[key - 1]
      if (this.editData.name === 'Headquarter') this.isHeadQuarter = true
      setTimeout(
        () =>
          this.form.setFieldsValue({
            head_quarter: this.editData.name,
            contact_number: this.editData.contact_number,
            latitude: this.editData.latitude,
            longtitude: this.editData.longtitude,
            isheadoffice: this.editData.isheadoffice,
          }),
        300,
      )
    },
    onSave() {
      this.editableData = null
      this.isEditBranch = false
      this.isHideEdit = false
      this.isHeadQuarter = false
    },
    onDelete(key) {
      console.log('onEdit : ', key)
      this.confirmModalAction(
        this.lbl['branch-delete-header'],
        this.lbl['branch-delete-title'],
        'danger',
        this.lbl['confirm-box-cancel-button'],
        true,
        () => {
          this.confirmModalSetting.isOpenModal = false
          this.confirmDelete(key)
        },
        () => {
          this.confirmModalSetting.isOpenModal = false
        },
      )
    },
    confirmDelete(key) {
      this.dataSource = _.filter(this.dataSource, item => {
        if (item.key != key) return item
      })

      this.setKey()

      this.alertModalAction(
        this.lbl['alert-box-success-header'],
        this.lbl['plan-reward-branch-delete-success-message'],
        'success',
        true,
      )
    },
    clickCancleEditBranch() {
      this.editableData = null
      this.isEditBranch = false
      this.isHideEdit = false
      this.isHeadQuarter = false
      this.form.setFieldsValue({
        head_quarter: '',
        contact_number: '',
        latitude: '',
        longtitude: '',
        isheadoffice: '',
      })
      this.setKey()
    },
    addBranch() {
      console.log('addBranch')
      Account.bzbsAnalyticTracking(
        'setting_account_page',
        'setting_account_branch',
        'click_branch_add',
        'on click',
      )
      if (this.dataSource.length <= this.BranchAmount) {
        this.form.validateFields((err, values) => {
          if (!err) {
            console.log('values of form: ', values)
            this.dataSource.push({
              name: values.head_quarter,
              display_name: values.head_quarter,
              contact_number: values.contact_number,
              latitude: values.latitude,
              longtitude: values.longtitude,
              import: false,
              isheadoffice: values.head_quarter == 'Headquarter' ? true : false,
            })

            this.isEditBranch = false
            this.isHideEdit = false
            this.isHeadQuarter = false

            this.setKey()
            this.resetFields()
            this.editData = {}
          }
        })
      } else {
        this.isEditBranch = false
        this.isHideEdit = false
        this.isHeadQuarter = false
      }
    },
    handleSubmit(e) {
      e.preventDefault()
      Account.bzbsAnalyticTracking(
        'setting_account_page',
        'setting_account_branch',
        'click_branch_add',
        'on click',
      )
      if (this.dataSource.length <= this.BranchAmount) {
        this.form.validateFields((err, values) => {
          if (!err) {
            if (this.editData.id) {
              _.forEach(this.dataSource, item => {
                if (item.key == this.editableData) {
                  item.name = values.head_quarter
                  item.display_name = values.head_quarter
                  item.contact_number = values.contact_number
                  item.latitude = values.latitude
                  item.longtitude = values.longtitude
                  item.import = this.editData.import
                    ? this.editData.import
                    : false
                  item.id = this.editData.id
                  item.isheadoffice =
                    values.head_quarter == 'Headquarter' ? true : false
                }
              })

              this.isEditBranch = false
              this.isHideEdit = false
              this.isHeadQuarter = false
            } else {
              _.forEach(this.dataSource, item => {
                if (item.key == this.editableData) {
                  item.iname = values.head_quarter
                  item.display_name = values.head_quarter
                  item.contact_number = values.contact_number
                  item.latitude = values.latitude
                  item.longtitude = values.longtitude
                  item.import = this.editData.import
                    ? this.editData.import
                    : false
                  item.isheadoffice =
                    values.head_quarter == 'Headquarter' ? true : false
                }
              })

              this.isEditBranch = false
              this.isHideEdit = false
              this.isHeadQuarter = false
            }

            this.setKey()
            this.resetFields()
            this.editData = {}
          }
        })
      } else {
        this.isEditBranch = false
        this.isHideEdit = false
        this.isHeadQuarter = false
      }
    },
    resetFields() {
      this.form.resetFields()
      this.latitude = ''
      this.longtitude = ''
    },
    setKey() {
      return new Promise(resolve => {
        var i = 1
        _.forEach(this.dataSource, item => {
          item.key = i
          i++
        })
        resolve(true)
      })
    },
    handleSaveAndNext() {
      this.params.branch = JSON.stringify(this.dataSource)
      this.handleLoading(true)
      return new Promise(resolve => {
        BzbsProfile.postAccountSetting(this.params)
          .then(res => {
            this.handleLoading(false)
            if (this.isUseFacebook && this.loginTypeList.includes('Facebook')) {
              this.$router.push({ name: 'ConnectFacebook' })
            } else {
              this.$router.push({ name: 'ConnectSetting' })
            }
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            this.$warning({
              title: this.lbl['alert-box-error-header'],
              content: error.response.data.error.message,
              centered: true,
              autoFocusButton: null,
              closable: true,
              okText: this.lbl['alert-box-success-close-button'],
              onOk: () => {},
            })
            resolve(error)
          })
      })
    },
    gotoConnectSetting() {
      this.$router.push({ name: 'ConnectSetting' })
    },
    gotoConnectFacebook() {
      Account.bzbsAnalyticTracking(
        'setting_account_page',
        'setting_account_branch',
        'click_setting_account_connect_facebook_tab',
        'on click',
      )
      this.$router.push({ name: 'ConnectFacebook' })
    },
    arrList(string) {
      var list = string.split(',')
      this.loginTypeList = list
    },
    previousAccountSetting() {
      this.$router.push({ name: 'AccountSetting' })
    },
    onFileChange(e) {
      let data = {
        business_name: this.userData.BusinessName,
        business_type: this.userData.BusinessType,
        email: this.userData.Email,
        district_code: this.userData.DistrictCode,
        district_name: this.userData.DistrictName,
        subdistrict_code: this.userData.SubDistrictCode,
        subdistrict_name: this.userData.SubDistrictName,
        province_code: this.userData.ProvinceCode,
        province_name: this.userData.ProvinceName,
        zipcode: this.userData.Zipcode,
        branch: JSON.stringify(this.dataSource),
        logo: this.userData.LogoUrl,
      }
      var files = e.target.files
      if (files.length) {
        return new Promise(resolve => {
          BzbsUserProfile.postUploadExcel(data, files)
            .then(res => {
              resolve(res.data)
            })
            .catch(error => {
              resolve(error)
            })
        })
      }
    },

    // Modal
    confirmModalAction(
      title,
      details,
      modalType,
      backto,
      isOpenModal,
      onConfirm,
      onCancel,
    ) {
      this.confirmModalSetting.title = title
      this.confirmModalSetting.details = details
      this.confirmModalSetting.modalType = modalType
      this.confirmModalSetting.isOpenModal = isOpenModal
      this.confirmModalSetting.backto = backto
      this.confirmModalSetting.onConfirm = onConfirm
      this.confirmModalSetting.onCancel = onCancel
    },
    alertModalAction(title, details, modalType, isOpenModal) {
      this.alertModalSetting.title = title
      this.alertModalSetting.details = details
      this.alertModalSetting.modalType = modalType
      this.alertModalSetting.isOpenModal = isOpenModal
      if (modalType == 'success' && isOpenModal) {
        setTimeout(() => {
          this.alertModalSetting.isOpenModal = false
        }, 2000)
      } else {
        if (isOpenModal)
          setTimeout(() => {
            location.reload()
          }, 2000)
      }
    },
  },
}
</script>

<style lang="scss">
@import '../styles/accountsetup_main.scss';

.wrap-branch-page {
  .none-event {
    pointer-events: none !important;
    color: $btn-disabled;
  }
  .ant-table .ant-table-thead > tr > td,
  .ant-table .ant-table-tbody > tr > td {
    padding: 15px 10px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.15px;
    color: #616161;
    border: initial;
  }

  tbody.ant-table-tbody {
    background-color: #fafafa;
  }

  .ant-table .ant-table-tbody {
    tr:nth-child(even) {
      background-color: #f5f5f5;
    }
  }
}
.ant-table-row-cell-break-word {
  word-break: inherit;
}
.dot-notification {
  position: absolute;
  right: -8px;
  top: 0;
}
</style>
